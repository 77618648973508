<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row class="d-flex justify-content-around">

        <!-- Search -->
        <b-col
          cols="6"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input

              class="d-inline-block mr-1"
              placeholder="Szukaj..."
            />

          </div>
        </b-col>

        <b-col
          cols="4"
          md="4"
        >
          <b-form-select
            v-model="invoiceTypeSelected"
            :options="invoiceTypesOptions"
            @change="fetchInvoices(invoiceTypeSelected)"
          />
        </b-col>

        <b-col
          cols="2"
          md="2"
          class="d-flex justify-content-end"
        >
          <b-button
            v-b-modal.modal-lg
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </b-col>

      </b-row>

    </div>

    <b-table
      :items="items"
      :fields="fields"
      primary-key="id"
      show-empty
      empty-text="Brak danych faktur"
      class="position-relative"
    >

      <!-- Column: Status -->
      <template #cell(status)="data">

        <div class="text-nowrap">
          <b-badge
            pill
            :variant="data.item.release_date !== null ? 'success' : 'secondary'"
          >
            {{ data.item.release_date === null ? 'Niewystawiona' : "wystawiona: " + data.item.release_date }}
          </b-badge>
        </div>
      </template>=

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'apps-invoice-preview', params: { id: data.item.id, type: data.item.invoicetable_type }})"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-show="data.item.release_date === null"
              @click="issue(data.item.id, data.item.invoicetable_type)"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">Wystaw</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Pobierz</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'apps-invoice-edit', params: { id: data.item.id, type: data.item.invoicetable_type } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteInvoice(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Duplicate</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>

    <b-modal
      id="modal-lg"
      ok-only
      ok-title="Zapisz"
      centered
      size="lg"
      title="Dostosuj wyświetlanie kolumn"
    >

      <div class="d-flex flex-column">

        <b-card-text class="mt-2 mb-2">
          Wybierz kolumny , które mają być wyświetlane na liście
        </b-card-text>

        <div class="mt-1">

          <b-form-checkbox
            id="5"
            :checked="true"
            name="check-button"
            switch
            inline
            disabled
          >
            <span>Numer</span>
          </b-form-checkbox>

          <b-form-checkbox
            id="6"
            :checked="visibleColumns.sellerName"
            name="check-button"
            switch
            inline
          >
            <span>Nazwa sprzedawcy</span>
          </b-form-checkbox>

          <b-form-checkbox
            id="7"
            :checked="visibleColumns.saleDate"
            name="check-button"
            switch
            inline
          >
            <span>Data sprzedaży</span>
          </b-form-checkbox>

          <b-form-checkbox
            id="8"
            :checked="visibleColumns.customerName"
            name="check-button"
            switch
            inline
          >
            <span>Nazwa nabywcy</span>
          </b-form-checkbox>

        </div>

        <div class="mt-1">

          <b-form-checkbox
            id="1"
            :checked="visibleColumns.status"
            name="check-button"
            switch
            inline
          >
            <span>Status</span>
          </b-form-checkbox>

          <b-form-checkbox
            id="2"
            :checked="visibleColumns.createdAt"
            name="check-button"
            switch
            inline
          >
            <span>Utworzono</span>
          </b-form-checkbox>

        </div>

      </div>

    </b-modal>

  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BBadge,
  BFormCheckbox, BDropdown, BDropdownItem, BFormSelect,
} from 'bootstrap-vue'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BFormSelect,
  },
  data() {
    return {
      invoiceType: null,
      fields: [
        {
          key: 'number',
          label: 'Numer',
          sortable: true,
        },
        // {
        //   key: 'client.company',
        //   label: 'Nazwa firmy',
        //   sortable: false,
        // },
        // {
        //   key: 'issuedDate',
        //   label: 'termin płatności',
        //   sortable: false,
        // },
        // {
        //   key: 'paymentDetails.price',
        //   label: 'kwota',
        //   sortable: false,
        // },
        {
          key: 'status',
          label: 'status',
          sortable: true,
        },
        {
          key: 'actions',
        },

      ],
      items: [],
      invoiceTypeSelected: null,
      invoiceTypesOptions: [
        { value: null, text: 'Wszystkie faktury' },
        { value: 'Vat', text: 'Faktury VAT' },
        { value: 'Advance_Payment', text: 'Faktury zaliczkowe' },
        { value: 'Corrective', text: 'Korekty' },
      ],
      visibleColumns: {
        saleDate: true,
        sellerName: true,
        customerName: true,
        status: true,
        createdAt: true,
      },
    }
  },
  mounted() {
    this.fetchInvoices()
  },
  methods: {
    async fetchInvoices(invoicetableType = null) {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      if (invoicetableType !== null) {
        config.params = { type: invoicetableType }
      }
      try {
        this.items = (await axios.get(`${process.env.VUE_APP_API_URL}Invoice`, config)).data
      } catch (e) {
        console.log(e)
      }
    },
    async issue(id, type) {
      let routeInvoiceType = 'InvoiceVat'

      if (type === 'Invoice_Prepayment') {
        routeInvoiceType = 'InvoiceAdvancePayment'
      }

      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}${routeInvoiceType}/${id}/Issue`, {}, config)
        await Swal.fire('Wystawiono fakturę', '', 'success')
        await this.fetchInvoices()
      } catch (e) {
        console.log(e)
        await Swal.fire('Nie udało się wystawić faktury', '', 'error')
      }
    },
    async filterInvoices() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }, params: { type: this.invoiceType } }
      try {
        this.items = (await axios.get(`${process.env.VUE_APP_API_URL}Invoice`, config)).data
      } catch (e) {
        console.log(e)
      }
    },
    async deleteInvoice(id) {
      await axios.delete(`${process.env.VUE_APP_API_URL}Invoice/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        Swal.fire(
          'Pomyślnie usunięto Fakturę',
          '',
          'success',
        )
        this.fetchInvoices()
      }).catch(() => {
        Swal.fire(
          'Coś poszło nie tak!',
          '',
          'error',
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
